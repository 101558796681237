<template>
  <div class="w-100">
    <div class="w-100 h-100 bg-white overflow-hidden shadow" style="max-width:26rem">
      <!-- {{personalPoint}} -->
      <div class="h-100 card rounded">
        <div class="card-header bg-dagee-blue p-1">
          <div class="d-flex align-items-center justify-content-between">
            <router-link :to="{name:'index'}" class="btn btn-sm px-2 text-dark">
              <font-awesome-icon :icon="['fas', 'chevron-left']" size='lg' />
            </router-link>
            <div class="flex-fill d-flex align-items-center text-dark">
              <b>我的景點照片</b>
            </div>
          </div>
        </div>

        <div class="w-100 h-100 bg-light">
          <div class="p-2 overflow-auto" style="height:calc(100% - 37px)">

            <div class="w-100 d-flex align-items-center justify-content-between text-secondary mb-2"
              v-for="(point, index) in personalImage" :key="point.uid">
              <div></div>
              <div class="flex-fill bg-white shadow-sm rounded px-2 pt-2 pb-0">
                <div class="w-100">
                  <div class="d-flex align-items-start justify-content-between">
                    <b style="min-width: 70px">景點名稱</b>
                    <span class="flex-fill">{{point.name}}</span>
                  </div>

                  <div class="d-flex align-items-start justify-content-between">
                    <b style="min-width: 70px">景點地址</b>
                    <span class="flex-fill">{{point.address}}</span>
                  </div>

                  <div class="d-flex align-items-start justify-content-between border-bottom pb-2">
                    <b style="min-width: 70px">圖片數量</b>
                    <span class="flex-fill">{{point.sub.length}}張</span>
                  </div>
                  <b-collapse class="pt-2" v-model="point.show">
                    <div v-for="(subImage, index) in point.sub" :key="subImage.uid"
                      class="d-flex align-items-start justify-content-between rounded p-2 mb-2 bg-light shadow-sm">
                      <div class="flex-fill">
                        <div class="d-flex align-items-center justify-content-center mb-2">
                          <b-img class="w-100" style="min-width:120px; max-width: 160px"
                            :src="getRealyUrl(subImage.image)" rounded :alt="subImage.image.name"
                            v-if="subImage.image">
                          </b-img>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">採用狀態</b>
                          <div>{{subImage.state}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">照片名稱</b>
                          <div>{{subImage.name}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">照片來源</b>
                          <div>{{subImage.source}}</div>
                        </div>
                        
                        <!-- <div class="d-flex align-items-start">
                          <b style="min-width: 70px">介紹</b>
                          <div>{{subPoint.descriptionDetail}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">地址</b>
                          <div>{{subPoint.address}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">官網</b>
                          <div class="pms-content-1">{{subPoint.websiteUrl}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">電話</b>
                          <div>{{subPoint.phone}}</div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">營業時間</b>
                          <div v-html="subPoint.openTime"></div>
                        </div>
                        <div class="d-flex align-items-start">
                          <b style="min-width: 70px">推送時間</b>
                          <div v-if="subPoint.transfer">{{subPoint.sendTime}}</div>
                          <div class="text-danger" v-else>尚未推送</div>
                        </div> -->
                      </div>
                      <!-- <button class="btn btn-sm px-2" @click="sendToCompany(subPoint)"
                        v-if="!subPoint.transfer">
                        <font-awesome-icon :icon="['fas', 'paper-plane']" size='lg' />
                      </button> -->
                    </div>
                  </b-collapse>
                  <div class="d-flex align-items-center justify-content-center py-2"
                    @click="point.show = !point.show">
                    <font-awesome-icon :icon="['fas', 'ellipsis-h']" size='lg' />
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>

    <comfirmBox :show.sync="confirm.show" :title="confirm.name" :context="confirm.context"
      :okfunc="confirm.okfunc">
    </comfirmBox>
  </div>
</template>

<script>
  import ctrl from "./PersonalPhoto";
  export default ctrl;

</script>
