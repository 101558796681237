import comfirmBox from "@/components/ComfirmBox";

export default {
  data() {
    return {
      path: process.env.VUE_APP_DATA_PATH,
      confirm: {
        show: false,
        title: "",
        context: "",
        okfunc: Function
      },


    }
  },

  computed: {
    personalImage: {
      get() {
        return this.$store.state.schedule.personalList.imageTemp;
      }
    },

    getRealyUrl() {
      return (path) => {
        if (path.indexOf("data/") > -1) {
          return this.path + path;
        } else {
          return path;
        }
      }
    },
  },

  mounted() {
    this.$store.dispatch("schedule/getPersonalPhoto");
  },

  components: {
    comfirmBox
  },
}
